<template>
  <div class="page-error">
    <p>页面不存在</p>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="less" scoped>
.page-error{
    width: calc(100vw);
    min-width:1440px;
    height: calc(100vh);
    min-height: 670px;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>